/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

body {
  color: #ffffff; /* Setzt die globale Schriftfarbe */
}

.logo-img {
  width: 230px; /* Oder die gewünschte Breite */
  height: auto; /* Beibehaltung des Seitenverhältnisses */
}

.my-simple-text{
  display: block;
  white-space: nowrap;
  text-decoration: none;
  line-height: 30px;
  overflow: hidden;
  text-align: center;
  font-size: 24px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-stepper .p-stepper-header .p-stepper-action {
  background: transparent;
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-title{
  color: blue;
  font-size: 20px;
}

.p-stepper .p-stepper-panels {
  background: transparent;
}

